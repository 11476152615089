import { Editor } from 'primereact/editor';
import React, { useState } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";


const TitleTextEditor = ({ text, setText }) => {
    const [textColor, setTextColor] = useState('#000000');
    const [textSize, setTextSize] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');

    const renderHeader = () => {
        return (
            <span className="ql-formats flex items-center">
                <span className='z-50'>
                    <select className="ql-size z-50 " onChange={(e) => setTextSize(e.target.value)} value={textSize} aria-label="Text Size">
                        <option value="small"></option>
                        <option value="normal"></option>
                        <option value="large"></option>
                        <option value="huge"></option>
                    </select>
                </span>
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <select className="ql-color" onChange={(e) => setTextColor(e.target.value)} value={textColor} aria-label="Text Color"></select>
                <select className="ql-background" onChange={(e) => setBackgroundColor(e.target.value)} value={backgroundColor} aria-label="Background Color"></select>

            </span>
        );
    };

    const header = renderHeader();
    const handleTextChange = (e) => {
        const newText = e.htmlValue;
        setText(newText);
    };

    return (
        <div className="card">
            <Editor value={text} onTextChange={handleTextChange} headerTemplate={header} style={{ height: '100px' }} />
        </div>
    );
};

export default TitleTextEditor;