import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { MdVideoSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ManageVideos = () => {
  const [videos, setVideos] = useState([]);
  const [refresh, setRefresh] = useState(false);
  // const [ limit, setLimit ] = useState( 10 )
  const [page, setPage] = useState(1);

  const handlePrev = () => {
    if (page === 1) {
      console.log(page);
      setPage(1);
      return;
    }
    setPage(page - 1);
  };
  const handleNext = () => {
    if (videos?.length < 10) {
      setPage(page);
      return;
    }
    setPage(page + 1);
  };

  // Get All Videos-------------------------------
  useEffect(() => {
    axios(`https://api.nagoriktv.com/videos/${page}/10`).then((res) => {
      // console.log(res?.data);
      setVideos(res?.data);
    });
  }, [refresh, page]);

  // Delete Videos-------------------
  const videoDelete = (id) => {
    Swal.fire({
      title: 'Are you sure to Delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://api.nagoriktv.com/delete-video/${id}`)
          .then((res) => {
            // console.log( res.data );
            setRefresh(!refresh);
            Swal.fire('Deleted!', 'Video Deleted Successfully!', 'success');
          })
          .catch((error) => {
            console.log(error);
          });
        // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  };

  // //   Update Video------------------------
  // const videoUpdate = (id) => {
  //   console.log('Update', id);
  // };

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <MdVideoSettings className="text-2xl" />
          <span>Manage Videos</span>
        </h2>
      </div>

      <div className="bg-white m-5 rounded-md p-8">
        <h1 className="text-2xl font-semibold">Video List</h1>
        <hr className="mb-4" />
        <div className="overflow-x-auto rounded-lg">
          <table className="table table-zebra">
            {/* head */}
            <thead>
              <tr className="text-black text-lg bg-slate-200">
                <th>Sl</th>
                <th>Video Title</th>
                <th className='flex justify-end mr-10'>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              {videos?.map((video, i) => (
                <tr key={i}>
                  {/* <th>{i + 1}</th> */}
                  <th>
                    {page === 1 ? (
                      <>{page === 1 || i + 1 === 10 ? '' : ''}</>
                    ) : (
                      <>{page === 1 || i + 1 === 10 ? page : page - 1}</>
                    )}

                    {i + 1 === 10 && page <= 1 ? page : ''}

                    {i + 1 === 10 ? 0 : i + 1}
                  </th>
                  <td className="font-semibold">{video?.video_title}</td>
                  <td className="flex gap-1 justify-end">
                    <Link
                      to={`/updatevideo/${video?.video_id}`}
                      // onClick={() => videoUpdate(video?.video_id)}
                      className="btn btn-sm btn-success text-white"
                    >
                      <FaEdit /> Edit
                    </Link>

                    <button
                      onClick={() => videoDelete(video?.video_id)}
                      className="btn btn-sm btn-error text-white"
                    >
                      <FaTrashAlt /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full mx-auto text-center mt-5">
          <button onClick={handlePrev} className="btn btn-sm mr-10 btn-accent">
            Prev
          </button>
          <button onClick={handleNext} className="btn btn-sm  btn-accent">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageVideos;
