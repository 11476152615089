import axios from 'axios';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TfiWrite } from 'react-icons/tfi';
import { Dna } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { ImCross } from 'react-icons/im';
import { userContext } from '../../../Context/UserContext/UserProvider/UserProvider';
import TextEditor from './TextEditor';
import TitleTextEditor from './TitleTextEditor';

const AddArticle = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [tags, setTags] = useState([]);
  const [imageStatus, setImageStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const { user } = useContext(userContext);
  const [preview, setPreview] = useState();
  const [showPreview, setShowPreview] = useState(false)
  const [text, setText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [subTitleText, setSubTitleText] = useState('');

  const isAdmin = user?.role === 'admin';
  const initialStatus = isAdmin ? 'published' : 'review';
  const [publishStatus, setPublishStatus] = useState(initialStatus);
  useEffect(() => {
    setPublishStatus(isAdmin ? 'published' : 'review');
  }, [isAdmin]);

  let images = [];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Get data form form----------------
  const onSubmit = (data) => {
    const today = new Date();
    const date = format(today, 'Y-LL-dd');

    // Create the article object and set its properties
    const article = {
      author: data?.author,
      title: data?.title,
      subtitle: data?.subtitle,
      title2: titleText,
      subtitle2: subTitleText,
      content: data?.content,
      paragraph: text,
      user_id: 1,
      publication_date: data?.date ? data?.date : date,
      status: data?.status ? data?.status : publishStatus,
      caption: data?.caption,
      categories: [{ category_id: parseInt(categoryId) }],
      images: allImages && allImages,
      tags: selectedTagId && selectedTagId,
      metadata: [],
      sub_categories: [
        { sub_category_id: parseInt(data?.subcategory && data?.subcategory) },
      ],
    };
    setPreview(article);
    setShowPreview(true)
  };

  // submit data to database---------------------
  const submitArticle = () => {
    const url = 'https://api.nagoriktv.com/articleapi/';
    axios
      .post(url, preview)
      .then((res) => {
        if (res.data) {
          Swal.fire('Good job!', 'Article is created Successfully!', 'success');
          reset();
          setImageStatus('');
          setSelectedTagId([]);
          setSelectedTags([]);
          setAllImages([])
          setPreview();
          setShowPreview(false)
          setText('')
          setTitleText("")
          setSubTitleText("")
        }
      })
      .catch((error) => {
      });
  }

  // Edit article----------
  const editArticle = () => {
    setShowPreview(false)
    window.scrollTo(0, 0)
  }

  //   Handle Tags-----------------------------------
  const handleTags = (e) => {
    const selectedTagValue = e.target.value;
    const tags = {
      tag_id: selectedTagValue,
    };
    setSelectedTagId([...selectedTagId, tags]);

    const selectedTagName = e.target.options[e.target.selectedIndex].text;
    if (!selectedTags.includes(selectedTagName)) {
      setSelectedTags([...selectedTags, selectedTagName]);
    }
  };

  //   Image upload here--------------------------------------
  const handleImage = (e) => {
    const getImages = e.target.files;
    setIsLoading(true);
    setImageStatus();
    const formData = new FormData();

    for (let i = 0; i < getImages?.length; i++) {
      formData.append('file', getImages[i]);

      const url = 'https://api.nagoriktv.com/images/upload';
      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          // const url = data[0]
          // const updatedText = text + `<div><img src="${url}" alt="Uploaded Image" /></div>`;
          // setText(updatedText);

          images.push({ file_name: getImages[i].name, file_path: data[0] });
          if (images.length === getImages.length) {
            setImageStatus('Successfully Upload !');
            setIsLoading(false);
            setAllImages(images);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  // handle category--------------------------------
  const handlecategory = (e) => {
    const category = e.target.value;

    const categoryId = category.split(' ')[0];
    setCategoryId(categoryId);

    const selctedSubCategories = allSubCategories?.filter(
      (category) => category?.category_id === categoryId
    );

    setSelectedSubCategories(selctedSubCategories);
  };

  // get all categories -------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/categories/')
      .then((res) => {
        setCategories(res?.data);
      })
      .catch((error) => {
      });
  }, []);

  // get all subcategories -------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/sub-category/')
      .then((res) => {
        setAllSubCategories(res?.data);
      })
      .catch((error) => {
      });
  }, []);

  // Get all tags-------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/tags/')
      .then((res) => {
        setTags(res?.data);
      })
      .catch((error) => {
      });
  }, []);

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <TfiWrite className="text-2xl" />
          <span>Add Article</span>
        </h2>
      </div>

      <div className='p-5 rounded-md shadow-lg m-10 bg-white'>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" flex gap-5 flex-col justify-center"
        >
          <div>
            <h2 className="text-2xl font-semibold">Add Article</h2>
            <hr className="mt-2" />
          </div>
          <div>
            <label className="label">
              <span className="label-text">Article Title : </span>
            </label>

            <input
              type="text"
              placeholder="Title Here"
              className="px-3 py-3 border w-full rounded-md"
              {...register('title', { required: 'Title is required' })}
            />

            {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
          </div>
          <div>
            <label className="label">
              <span className="label-text">Article Title2 : </span>
            </label>
            <TitleTextEditor text={titleText} setText={setTitleText} />
          </div>

          <div>
            <label className="label">
              <span className="label-text">Article Subtitle : </span>
            </label>
            <input
              type="text"
              placeholder="Enter Subtitle"
              {...register('subtitle', { required: 'Subtitle is required' })}
              className="px-3 py-3 border w-full rounded-md"
            />

            {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
          </div>
          <div>
            <label className="label">
              <span className="label-text">Article Subtitle2 : </span>
            </label>
            <TitleTextEditor text={subTitleText} setText={setSubTitleText} />
          </div>

          {/* <div>
            <label className="label">
              <span className="label-text">Content : </span>
            </label>
            <textarea
              type="text"
              placeholder="Write Content"
              className="px-3 h-44 py-3 border w-full rounded-md"
              {...register('content')}
            />
            {errors.content && <span className='text-error'>{errors.content.message}</span>}
          </div> */}

          <div className="grid grid-cols-3 gap-5">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Category : </span>
              </label>
              <select
                onChange={handlecategory}
                className="px-3 py-3 border w-full rounded-md"
              // { ...register( "category", { required: 'Please Select Category' } ) }
              >
                <option value="">Select Category</option>
                {categories?.map((category) => (
                  <option
                    key={category?.category_id}
                    value={category?.category_id + ' ' + category?.name}
                  >
                    {category?.name}
                  </option>
                ))}
              </select>
              {/* { errors.category && <span className='text-error'>{ errors.category.message }</span> } */}
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Sub Category : </span>
              </label>
              <select
                className="px-3 py-3 border w-full rounded-md"
                {...register('subcategory', { required: false })}
              >
                <option value="">Select Subcategory</option>
                {selectedSubCategories?.map((category) => (
                  <option key={category?.id} value={category?.id}>
                    {category?.sub_category_name}
                  </option>
                ))}
              </select>
              {/* { errors.subcategory && <span className='text-error'>{ errors.subcategory.message }</span> } */}
            </div>

            <div>
              <label className="label">
                <span className="label-text">Publication Date : </span>
              </label>
              <input
                type="date"
                placeholder="date"
                className="px-3 py-3 border w-full rounded-md"
                {...register('date', { required: false })}
              />
              {/* {errors.content && <span className='text-error'>{errors.content.message}</span>} */}
            </div>
          </div>

          {/* <div className="flex  gap-5 w-full"> */}
          <div className="form-control w-full">
            <label className="label">
              <div className="flex gap-4 items-center">
                <span className="label-text">Tag : </span>
                <div className="flex gap-1">
                  {selectedTags.map((tag, index) => (
                    <span
                      key={index}
                      className="flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700"
                    >
                      #{tag}
                      <ImCross
                        className="ml-2 outline-none focus:outline-none text-red-600"
                        onClick={() => {
                          setSelectedTags((prevTags) =>
                            prevTags.filter((_, i) => i !== index)
                          );
                        }}
                      />
                    </span>
                  ))}
                </div>
              </div>
            </label>
            <select
              className="px-3 py-3 border w-full rounded-md"
              {...register('tag', { required: 'Please Enter Tag!' })}
              onChange={(e) => handleTags(e)}
            >
              <option value="">Select Tag</option>
              {tags?.map((tag) => (
                <option key={tag?.tag_id} value={tag?.tag_id}>
                  {tag?.name}
                </option>
              ))}
            </select>
            {errors.content && (
              <span className="text-error">{errors.content.message}</span>
            )}
          </div>

          {/* New Dropdown for Publish Status */}
          {isAdmin && (
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Status: </span>
              </label>
              <select
                className="px-3 py-3 border w-full rounded-md"
                onChange={(e) => setPublishStatus(e.target.value)}
                defaultValue={publishStatus}
                {...register('status', { required: 'Please add status' })}
              >
                <option value="draft">Draft</option>
                <option value="review">In Review</option>
                <option value="published">Published</option>
              </select>
            </div>
          )}

          <div>
            <label className="label">
              <span className="label-text">Author Name : </span>
            </label>
            <input
              type="text"
              placeholder="Enter Author Name"
              {...register('author', { required: 'Author is required' })}
              className="px-3 py-3 border w-full rounded-md"
            />

            {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
          </div>
          <TextEditor text={text} setText={setText} handleImage={handleImage} />
          <div className="p-5 bg-[#F8F8FF] mx-20 mt-10">
            <span className="label-text font-semibold text-xl ">Upload : </span>
            <label
              htmlFor="upload-photo"
              className="label flex flex-col justify-center items-center cursor-pointer bg-green-100 border border-dashed border-black rounded-lg p-14 mt-2"
            >
              <div className="flex gap-5 justify-between w-full">
                <div>
                  {allImages && (
                    <img
                      src={allImages[0]?.file_path}
                      alt=""
                      className="rounded-md"
                    />
                  )}
                </div>
                <div className="w-full flex flex-col items-center justify-center">
                  <p className="font-semibold">
                    Drop your File or <span className="text-primary">Browse</span>
                  </p>
                  <hr className="border-b border-black border-dashed w-full my-2" />

                  <p className="text-xl font-bold text-success mt-5 ">
                    {imageStatus}
                  </p>

                  {isLoading && (
                    <Dna
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  )}
                </div>
              </div>
              <div>
           
            {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
          </div>
            </label>
            <input
              onChange={handleImage}
              multiple
              type="file"
              id="upload-photo"
              className="px-3 py-3 border w-full rounded-md hidden"
            />
          </div>
          <div>
             <label className="label">
              <span className="label-text">Image Caption : </span>
            </label>
            <input
              type="text"
              placeholder="Enter Image Caption"
              {...register('caption', { required: false })}
              className="px-3 py-3 border w-full rounded-md"
            />
          </div>

          <div className="w-full flex justify-center">
            <input
              type="submit"
              value="Preview"
              className={`${showPreview ? 'hidden' : 'block'} bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white`}
            />
          </div>
        </form>

        {
          preview &&
          <div className={`${showPreview ? 'block' : 'hidden'}`}>
            <hr className="border-[#D9D9D9] border-2 my-3"></hr>
            <div className="title">
              <h2 className="text-xl md:text-2xl lg:text-4xl text-black">
                <div dangerouslySetInnerHTML={{ __html: preview?.title2 }} />
              </h2>
              <h2 className="text-base md:text-xl lg:text-2xl mt-2 text-black">
                {/* {preview?.subtitle} */}
                <div dangerouslySetInnerHTML={{ __html: preview?.subtitle2 }} />
              </h2>
              <hr className="border-[#D9D9D9] border-2 mt-3"></hr>
            </div>

            <div>
              <img
                src={preview?.images[0]?.file_path}
                alt=""
                className="rounded-sm w-full object-cover"
              />
              <div dangerouslySetInnerHTML={{ __html: preview?.paragraph }} />
            </div>
            <hr className="border-[#D9D9D9] border-2 my-3"></hr>
            <div className="w-full flex justify-center gap-5">

              <button onClick={() => submitArticle()} className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white ">Submit</button>

              <button onClick={() => editArticle()} className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white ">Edit</button>

            </div>
          </div>
        }
      </div>

    </div>
  );
};

export default AddArticle;
