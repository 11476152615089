import axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPoll } from 'react-icons/fa';
import { Dna } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const UpdatePoll = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [imageStatus, setImageStatus] = useState('');
    const [image, setImage] = useState('');
    const [poll, setPoll] = useState();
    const [refresh, setRefresh] = useState(true)

    const { id } = useParams();

    // Get single Polls----------------------------
    useEffect(() => {
        axios(`https://api.nagoriktv.com/polls/${id}`)
            .then((res) => {
                // console.log(res?.data);
                setPoll(res?.data);
                // console.log(res?.data?.image);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id, refresh]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        const today = new Date();
        const date = format(today, 'Y-LL-dd');

        const updatePoll = {
            id: id,
            question: data?.question ? data?.question : poll?.question,
            option_1: data?.option_1 ? data?.option_1 : poll?.option_1,
            option_2: data?.option_2 ? data?.option_2 : poll?.option_2,
            option_3: data?.option_3 ? data?.option_3 : poll?.option_3,
            option_1_vote: poll?.option_1_vote,
            option_2_vote: poll?.option_2_vote,
            option_3_vote: poll?.option_3_vote,
            publication_date: date,
            image: image ? image : poll?.image
        }


        const url = `https://api.nagoriktv.com/polls/${id}`;
        axios
            .put(url, updatePoll)
            .then((res) => {
                // console.log( res );
                Swal.fire('Good job!', 'Poll is Updated Successfully!', 'success');
                reset();
                setRefresh(!refresh)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleImage = (e) => {
        setIsLoading(true);
        const formData = new FormData();
        const image = e.target.files[0];

        formData.append('file', image);

        const url = 'https://api.nagoriktv.com/polls/upload';
        fetch(url, {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                setImage(data[0]);
                setIsLoading(false);
                setImageStatus('Successfully Upload !');
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };



    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-2xl font-bold flex gap-2 items-center">
                    <FaPoll className="text-2xl" />
                    <span>Update Poll</span>
                </h2>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
            >
                <div>
                    <h1 className="text-2xl font-semibold">Update Poll</h1>
                    <hr className="mt-2" />
                </div>

                {/* video Title----------- */}
                <div>
                    <label className="label">
                        <span className="label-text">Question : </span>
                    </label>
                    <input
                        type="text"
                        placeholder="Question Here"
                        defaultValue={poll?.question}
                        className="px-3 py-2 border w-full rounded-md"
                        {...register('question', { required: 'Question is required' })}
                    />
                    {errors.name && (
                        <span className="text-error">{errors.name.message}</span>
                    )}
                </div>

                <div>
                    <label className="label">
                        <span className="label-text font-bold ">Select Options : </span>
                    </label>
                    <div className="flex gap-5 items-center">
                        <input
                            type="text"
                            placeholder="Enter option_1"
                            defaultValue={poll?.option_1}
                            className="px-3 py-2 border w-full rounded-md"
                            {...register('option_1', { required: 'Option is required' })}
                        />
                        <input
                            type="text"
                            placeholder="Enter option_2"
                            defaultValue={poll?.option_2}
                            className="px-3 py-2 border w-full rounded-md"
                            {...register('option_2', { required: 'Option is required' })}
                        />
                        <input
                            type="text"
                            placeholder="Enter option_3"
                            defaultValue={poll?.option_3}
                            className="px-3 py-2 border w-full rounded-md"
                            {...register('option_3', { required: 'Option is required' })}
                        />
                    </div>
                </div>

                <div className="p-5 bg-[#F8F8FF] mx-20 my-5 ">
                    <span className="label-text font-semibold text-xl ">Upload : </span>
                    <label
                        htmlFor="upload-photo"
                        className="label flex flex-col justify-center items-center cursor-pointer bg-green-100 border border-black border-dashed rounded-lg p-14 mt-2"
                    >
                        <div className='flex gap-5 justify-between w-full'>
                            <div>
                                {
                                    image ?
                                        <img src={image} alt="" className='rounded-md' />
                                        :
                                        <img src={poll?.image} alt="" className='rounded-md' />
                                }
                            </div>
                            <div className='w-full flex flex-col items-center justify-center'>
                                <p className="font-semibold">
                                    Drop your File or <span className="text-primary">Browse</span>
                                </p>
                                <hr className="border-b border-black border-dashed w-full my-2" />

                                <p className="text-xl font-bold text-success mt-5 ">
                                    {imageStatus}
                                </p>

                                {isLoading && (
                                    <Dna
                                        visible={true}
                                        height="80"
                                        width="80"
                                        ariaLabel="dna-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="dna-wrapper"
                                    />
                                )}
                            </div>
                        </div>
                    </label>
                    <input
                        onChange={handleImage}
                        type="file"
                        id="upload-photo"
                        className="px-3 py-2 border w-full rounded-md hidden"
                    />
                </div>

                <div className="w-full flex justify-center">
                    <input
                        type="submit"
                        value="Update"
                        className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-2 w-full rounded-md text-white "
                    />
                </div>
            </form>
        </div>
    );
};

export default UpdatePoll;