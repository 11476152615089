import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdRateReview } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const UpdateVideo = () => {
  const [video, setVideo] = useState();
  const [refresh, setRefresh] = useState(false);
  const [categories, setCategories] = useState([]);

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {

    const updateVideo = {
      video_id: video?.video_id,
      video_url: data?.url ? data?.url : video?.video_url,
      video_title: data?.title ? data?.title : video?.video_title,
      video_meta: data?.metadata ? data?.metadata : video?.video_meta,
    };


    axios
      .put('https://api.nagoriktv.com/videos/', updateVideo)
      .then((res) => {
        if (res.data) {
          Swal.fire('Good job!', 'Video is Updated Successfully!', 'success');
          reset();
          setRefresh(!refresh);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  //   Get single Video------------------------------------
  useEffect(() => {
    axios(`https://api.nagoriktv.com/videos/${id}`)
      .then((res) => {
        // console.log(res?.data);
        setVideo(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, refresh]);

  // get all categories -------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/categories/')
      .then((res) => {
        // console.log( res.data );
        setCategories(res?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);


  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <MdRateReview className="text-3xl" />
          <span>Update Video</span>
        </h2>
      </div>

      {/* Update Form------------------------------- */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
      >
        <div>
          <h1 className="text-2xl font-semibold">Update Video</h1>
          <hr className="mt-2" />
        </div>

        {/* video Title----------- */}
        <div>
          <label className="label">
            <span className="label-text">Video Title : </span>
          </label>
          <input
            type="text"
            // placeholder={video?.video_title}
            defaultValue={video?.video_title}
            className="px-3 py-2 border w-full rounded-md"
            {...register('title', { required: false })}
          />
          {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
        </div>

        {/* video url------------------- */}
        <div>
          <label className="label">
            <span className="label-text">Video URL : </span>
          </label>
          <input
            type="text"
            // placeholder={video?.video_url}
            defaultValue={video?.video_url}
            className="px-3 py-2 border w-full rounded-md"
            {...register('url', { required: false })}
          />
          {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
        </div>

        {/* <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Video Category : </span>
          </label>
          <select
            className="px-3 py-2 border w-full rounded-md"
            {...register('category_id', { required: 'Please Select Category' })}
          >
            <option value="">Select Category</option>
            {categories?.map((category) => (
              <option key={category?.category_id} value={category?.category_id}>
                {category?.name}
              </option>
            ))}

          </select>
          {errors.category_id && (
            <span className="text-error">{errors.category_id.message}</span>
          )}
        </div> */}

        {
          video?.video_url &&
          <img
            src={`https://img.youtube.com/vi/${video?.video_url?.split('/').pop()}/maxresdefault.jpg`}
            alt=""
            className="w-1/2 mx-auto h-full rounded-md object-cover"
          />
        }

        {/* <div>
          <label className="label">
            <span className="label-text">Video Meta: : </span>
          </label>
          <input
            type="text"
            placeholder={video?.video_meta ? video?.video_meta : 'Metadata'}
            className="px-3 py-2 border w-full rounded-md"
            {...register('metadata', { required: false })}
          />
          {errors.content && <span className='text-error'>{errors.content.message}</span>}
        </div> */}

        <div className="w-full flex justify-center">
          <input
            type="submit"
            value="Update"
            className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-2 w-full rounded-md text-white "
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateVideo;
