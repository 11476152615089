import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash, FaUsersCog } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const UpdateUser = () => {
  const [user, setUser] = useState();
  const [refresh, setRefresh] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const id = useParams();
  const { register, handleSubmit, reset, formState: { errors }, } = useForm();

  useEffect(() => {
    axios(`https://api.nagoriktv.com/users/${id?.id}`)
      .then((res) => {
        setUser(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, refresh]);

  const onSubmit = (data) => {
    const UpdateUser = {
      user_id: user?.user_id,
      username: data?.name ? data?.name : user?.username,
      email: data?.email ? data?.email : user?.email,
      password: data?.password,
      role: data?.role ? data?.role : user?.role,
    };

    // console.log(UpdateUser);

    const url = 'https://api.nagoriktv.com/users/';
    axios
      .put(url, UpdateUser)
      .then((res) => {
        // console.log(res);
        Swal.fire('Good job!', 'User Update Successful!', 'success');
        reset();
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire('Ooops!', 'User Not Updated!', 'error');
      });
  };

  const handlePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <FaUsersCog className="text-4xl" />
          <span>Update User</span>
        </h2>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-8 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
      >
        <div>
          <h1 className="text-2xl font-semibold">Update User</h1>
          <hr className="mt-2" />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Update User Name : </span>
          </label>
          <input
            type="text"
            defaultValue={user?.username}
            className="px-3 py-2 border w-full rounded-md"
            {...register('name', { required: false })}
          />
          {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
        </div>
        <div>
          <label className="label">
            <span className="label-text">Update Email : </span>
          </label>
          <input
            type="email"
            defaultValue={user?.email}
            className="px-3 py-2 border w-full rounded-md"
            {...register('email', { required: false })}
          />
          {/* {errors.email && <span className='text-error'>{errors.email.message}</span>} */}
        </div>
        <div>
          <label className="label">
            <span className="label-text">Password : </span>
          </label>
          <div className='relative'>
            {
              showPassword ? <FaEye onClick={() => handlePassword()} className='absolute right-4 top-3 text-lg cursor-pointer' />
                :
                <FaEyeSlash onClick={() => handlePassword()} className='absolute right-4 top-3 text-lg cursor-pointer' />
            }
            <input
              type={showPassword ? "password" : "text"}
              placeholder="Enter Update Password"
              className="px-3 py-2 border w-full rounded-md"
              {...register('password', { required: 'Enter Your Password' })}
            />
          </div>
          {errors.password && <span className='text-error'>{errors.password.message}</span>}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">User Role : </span>
          </label>
          <select
            className="px-3 py-2 border w-full rounded-md"
            {...register('role', { required: false })}
          >
            <option value={user?.role}>{user?.role}</option>
            <option value="admin">admin</option>
            <option value="reviewer">reviewer</option>
            <option value="editor">editor</option>
          </select>
          {errors.role && (
            <span className="text-error">{errors.role.message}</span>
          )}
        </div>
        <div className="w-full flex justify-center">
          <input
            type="submit"
            value="Update"
            className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-2 w-full rounded-md text-white "
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateUser;
