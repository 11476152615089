import React, { useContext } from 'react';
import Navbar from '../../Shared/Navbar/Navbar';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {
  FaAngleDown,
  FaClipboardList,
  FaCog,
  FaCogs,
  FaComments,
  FaFileUpload,
  FaPoll,
  FaScroll,
  FaTag,
  FaTags,
  FaThLarge,
  FaTrashAlt,
  FaUserPlus,
  FaUsers,
  FaUsersCog,
  FaUsersSlash,
  FaVideo,
} from 'react-icons/fa';
import { BsSignpost2 } from "react-icons/bs";
import { RiFileList3Fill, RiVideoAddFill } from 'react-icons/ri';
import { BiLogInCircle } from 'react-icons/bi';
import { GiScrollQuill } from 'react-icons/gi';
import { TfiWrite } from 'react-icons/tfi';
import { MdCategory, MdRateReview, MdVideoSettings } from 'react-icons/md';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'react-headless-accordion';
import { userContext } from '../../Context/UserContext/UserProvider/UserProvider';
import Login from '../../Shared/Login/Login';
import { ImUpload } from 'react-icons/im';

const Main = () => {
  const { user, setUser } = useContext(userContext);
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage?.removeItem('userId');
    navigate('/');
    setUser();
  };

  return (
    <div>
      {user?.user_id ? (
        <div>
          <Navbar></Navbar>
          <div className="drawer lg:drawer-open">
            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content bg-[#f5f4f4]">
              {/* Page content Here---------- */}
              <Outlet></Outlet>
            </div>
            <div className="drawer-side min-h-screen">
              <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
              <div className="menu p-0 w-72 h-full bg-[#333436] text-white">
                {/* <!-- Sidebar content here --> */}
                <div>
                  <Link
                    to="/"
                    className="flex gap-3 p-5 items-center hover:text-[#1083B2]"
                  >
                    <FaThLarge className="text-xl" />
                    <span className="">Dashboard</span>
                  </Link>

                  <Accordion className="px-5">
                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center">
                        <div className="flex gap-2 my-2">
                          <FaUsers className="text-xl" />
                          <h3 className="">User Management</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>

                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">
                          {user?.role === 'admin' && (
                            <Link
                              to="/adduser"
                              className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                            >
                              <FaUserPlus className="text-xl" />
                              <span className="">Add Users</span>
                            </Link>
                          )}
                          <Link
                            to="/manageuser"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaUsersCog className="text-xl" />
                            <span className="">Manage Users</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center my-2">
                        <div className="flex gap-2 my-2">
                          <FaClipboardList className="text-xl" />
                          <h3 className="">Category</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>

                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">
                          <Link
                            to="/addcategory"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaClipboardList className="text-xl" />
                            <span className="">Add Category</span>
                          </Link>
                          <Link
                            to="/managecategory"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaTrashAlt className="text-xl" />
                            <span className="">Manage Category</span>
                          </Link>
                          <Link
                            to="/addsubcategory"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <MdCategory className="text-xl" />
                            <span className="">Add Sub Category</span>
                          </Link>
                          <Link
                            to="/managesubcategory"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaTrashAlt className="text-xl" />
                            <span className="">Manage Sub Category</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* <hr className='border border-b-1 border-black my-1' /> */}

                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center my-2">
                        <div className="flex gap-2 my-2">
                          <RiFileList3Fill className="text-xl" />
                          <h3 className="">Article</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>

                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">
                          <Link
                            to="/addarticle"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <TfiWrite className="text-xl" />
                            <span className="">Add Article</span>
                          </Link>
                          <Link
                            to="/reviewarticle"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <MdRateReview className="text-xl" />
                            <span className="">Review Article</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center my-2">
                        <div className="flex gap-2 my-2">
                          <FaTags className="text-xl" />
                          <h3 className="">Tags</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>

                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">
                          <Link
                            to="/addtag"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaTag className="text-xl" />
                            <span className="">Add Tag</span>
                          </Link>
                          <Link
                            to="/deletetag"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaTrashAlt className="text-xl" />
                            <span className="">Manage Tag</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* <hr className='border border-b-1 border-black my-1' /> */}

                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center my-2">
                        <div className="flex gap-2 my-2">
                          <FaVideo className="text-xl" />
                          <h3 className="">Video</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>

                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">
                          <Link
                            to="/addvideos"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <RiVideoAddFill className="text-xl" />
                            <span className="">Add Video</span>
                          </Link>
                          <Link
                            to="/managevideos"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <MdVideoSettings className="text-xl" />
                            <span className="">Manage Video</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center my-2">
                        <div className="flex gap-2 my-2">
                          <FaPoll className="text-xl" />
                          <h3 className="">Polls</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>
                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">
                          <Link
                            to="/addpoll"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaPoll className="text-xl" />
                            <span className="">Add Polls</span>
                          </Link>
                          <Link
                            to="/managepoll"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaCog className="text-xl" />
                            <span className="">Manage Polls</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* Posters----------- */}
                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center my-2">
                        <div className="flex gap-2 my-2">
                          <BsSignpost2 className="text-xl" />
                          <h3 className="">Posters</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>

                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">

                          <Link
                            to="/shareimage"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <ImUpload className="text-xl" />
                            <span className="">Upload Poster</span>
                          </Link>
                          <Link
                            to="/manageshareimage"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaCogs className="text-xl" />
                            <span className="">Manage Poster</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* Random file Add----------------------- */}
                    <AccordionItem>
                      <AccordionHeader className="w-full flex justify-between items-center my-2">
                        <div className="flex gap-2 my-2">
                          <FaFileUpload className="text-xl" />
                          <h3 className="">Random Files</h3>
                        </div>
                        <FaAngleDown />
                      </AccordionHeader>

                      <AccordionBody className="px-4 bg-black rounded-md">
                        <div className="accordion-body py-2 flex flex-col gap-2">
                          <Link
                            to="/addscroll"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaScroll className="text-xl" />
                            <span className="">Add Highlight News</span>
                          </Link>
                          <Link
                            to="/managescroll"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <GiScrollQuill className="text-xl" />
                            <span className="">All Highlight News</span>
                          </Link>
                          <Link
                            to="/managecomment"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaComments className="text-xl" />
                            <span className="">All Comments</span>
                          </Link>
                          <Link
                            to="/frontendusers"
                            className="flex gap-3 py-1 items-center hover:text-[#1083B2]"
                          >
                            <FaUsersSlash className="text-xl" />
                            <span className="">All Front-end users</span>
                          </Link>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* <hr className='border border-b-1 border-black my-1' /> */}
                  </Accordion>
                  <div className="px-5">
                    {user?.user_id ? (
                      <div
                        onClick={handleLogOut}
                        className="flex gap-2 my-2 items-center font-semibold cursor-pointer"
                      >
                        <BiLogInCircle className="text-xl" />
                        <h3 className="text-lg">Logout</h3>
                      </div>
                    ) : (
                      <Link
                        to="/login"
                        className="flex gap-2 my-2 items-center font-semibold cursor-pointer"
                      >
                        <BiLogInCircle className="text-xl" />
                        <h3 className="text-lg">Login</h3>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Main;
