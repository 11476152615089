import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { FaEdit, FaTrashAlt, FaUsersCog } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { userContext } from '../../../Context/UserContext/UserProvider/UserProvider';
import { useNavigate } from 'react-router-dom';

const ManageUsers = () => {
    const { user } = useContext(userContext);
    const [users, setUsers] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://api.nagoriktv.com/users/')
            .then((res) => res.json())
            .then((data) => {
                setUsers(data);
            });
    }, [refresh]);

    //   console.log(user);
    // user Delete-------------------------------
    const handleUserDelete = (id) => {
        const deleteUser = {
            user_id: id,
        };
        if (user?.role !== 'admin') {
            Swal.fire('Sorry!', 'You are not allow to Delete user!', 'error');
            return;
        }
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('https://api.nagoriktv.com/users/', { data: deleteUser })
                    .then((res) => {
                        Swal.fire('Deleted!', 'User Deleted Successfully!', 'success');
                        setRefresh(!refresh);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });

    };

    const handleUserUpdate = (id) => {
        if (user?.role !== 'admin') {
            Swal.fire('Sorry!', 'Only Admin is allowed to Update user!', 'error');
            return;
        }
        navigate(`/updateuser/${id}`);
    };

    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-2xl font-bold flex gap-2 items-center">
                    <FaUsersCog className="text-4xl" />
                    <span>Manage User</span>
                </h2>
            </div>

            {/* User List ---------- */}
            <div className="bg-white m-5 rounded-md p-8">
                <h1 className="text-2xl font-semibold">Users List</h1>
                <hr className="mb-4" />
                <div className="overflow-x-auto rounded-lg">
                    <table className="table table-zebra">
                        {/* head */}
                        <thead>
                            <tr className="text-black text-lg bg-slate-200">
                                <th>Sl</th>
                                <th>User Name</th>
                                <th>Email</th>
                                {/* <th>Password</th> */}
                                <th>Role</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row 1 */}
                            {users?.map((loginUser, i) => (
                                // console.log(user)
                                < tr key={i} >
                                    <th>{i + 1}</th>
                                    <td className="text-lg font-semibold">{loginUser?.username}</td>
                                    <td>{loginUser?.email}</td>
                                    {/* <td>Blue</td> */}
                                    < td > {loginUser?.role}</td>
                                    <td className="flex gap-1">
                                        {
                                            (user?.role === 'admin') ? <button
                                                onClick={() => handleUserUpdate(loginUser?.user_id)}
                                                className="btn btn-sm btn-success text-white">
                                                <FaEdit /> Edit
                                            </button>
                                                :
                                                <button
                                                    className="btn btn-sm btn-success text-white" disabled>
                                                    <FaEdit /> Edit
                                                </button>
                                        }

                                        <button
                                            onClick={() => handleUserDelete(loginUser?.user_id)}
                                            className="btn btn-sm btn-error text-white">
                                            <FaTrashAlt /> Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >
        </div >
    );
};

export default ManageUsers;
