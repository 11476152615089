import React, { useContext } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { userContext } from '../../Context/UserContext/UserProvider/UserProvider';
import { BiLogInCircle } from 'react-icons/bi';

const Navbar = () => {
  const { user, setUser } = useContext(userContext);
  const navigate = useNavigate();
  // console.log(user);

  const handleLogOut = () => {
    localStorage?.removeItem('userId');
    navigate('/');
    setUser();
  };

  return (
    <div className="bg-[#171717]">
      <div className="w-11/12 mx-auto py-2 flex gap-3 items-center">
        <label
          htmlFor="my-drawer-2"
          className="text-white text-xl drawer-button lg:hidden"
        >
          <FaBars />
        </label>

        <div className="flex justify-between w-full items-center">
          <Link to="/">
            <h2 className="text-2xl text-white font-bold">NAGORIK tv</h2>
          </Link>
          <div className="flex gap-2 items-center bg-white rounded-full p-1 px-2">
            {/* <h2 className='w-8 h-8 bg-slate-300 rounded-full'>
                            <img src={ profile } alt="" />
                        </h2> */}
            {/* {
                            user?.user_id ? <h2 className='font-medium px-4'>{user?.username}</h2> : <h2 className='font-medium px-4'>No User</h2>
                        } */}

            {user?.user_id ? (
              <div className="dropdown dropdown-end">
                <label tabIndex={0} className="cursor-pointer font-medium px-4">
                  {user?.username}
                </label>
                <ul
                  tabIndex={0}
                  className="menu dropdown-content z-[1] p-2 rounded-md px-4 w-48 w mt-4 shadow bg-[#158488] text-primary-content "
                >
                  <div className="text-lg flex flex-col gap-2">
                    <p className="flex text-white font-semibold w-full">
                      Name: {user?.username}
                    </p>
                    <p className="flex text-white font-semibold w-full">
                      Role: {user?.role}
                    </p>
                    <div
                      onClick={handleLogOut}
                      className="flex gap-2 my-2 text-white hover:text-red-400 duration-500 items-center font-semibold cursor-pointer"
                    >
                      <BiLogInCircle className="text-2xl font-bold" />
                      <h3 className="text-lg">Logout</h3>
                    </div>
                  </div>
                </ul>
              </div>
            ) : (
              <h2 className="font-medium px-4">No User</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
