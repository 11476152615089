import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ImUpload } from 'react-icons/im';
import { Dna } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const UpdateShareImage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [imageStatus, setImageStatus] = useState('');
    const [image, setImage] = useState('');
    const [posterTitle, setPosterTitle] = useState();
    const navigate = useNavigate();
    const [poster, setPoster] = useState()

    const { id } = useParams();
    // console.log(id);

    // Get Single Poster detail----------
    useEffect(() => {
        axios(`https://api.nagoriktv.com/get-all-banner-image/?id=${id}`)
            .then(res => {
                console.log(res?.data);
                setPoster(res?.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, [id])

    // Image Upload------
    const handleImage = (e) => {
        setIsLoading(true);
        setImageStatus('')
        const formData = new FormData();
        const image = e.target.files[0];

        formData.append('file', image);

        const url = 'https://api.nagoriktv.com/banner-image/upload';
        fetch(url, {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                setImage(data[0]);
                setIsLoading(false);
                setImageStatus('Successfully Upload !');
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    // poster title-----
    const handlePosterTitle = (e) => {
        setPosterTitle(e.target?.value)
    }


    // Submit Poster --------------
    const ImageUpload = () => {
        const url = 'https://api.nagoriktv.com/get-all-banner-image/'

        const updateImage = {
            id: id,
            image_url: image ? image : poster?.image_url,
            title: posterTitle ? posterTitle : poster?.title
        }
        console.log(updateImage);
        axios.put(url, updateImage)
            .then(res => {
                // console.log(res);
                Swal.fire('Good job!', `${res?.data?.message}`, 'success');
                setImage();
                setImageStatus();
                navigate('/manageshareimage')
            })
            .catch(error => {
                console.log(error);
            })
    }


    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-2xl font-semibold flex gap-2 items-center">
                    <ImUpload className="text-2xl" />
                    <span>Update Poster Image</span>
                </h2>
            </div>

            {/* Upload Share Image-------------------------- */}
            <div className="p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center">
                {
                    image ?
                        <div className='w-1/6 mx-auto'>
                            <img src={image} alt="" className='w-full object-cover' />

                        </div>
                        :
                        <div className='w-1/6 mx-auto'>
                            <img src={poster?.image_url} alt="" className='w-full object-cover' />
                        </div>
                }

                <div className="p-5 bg-[#F8F8FF] mx-20 my-5 rounded-md">
                    <div className='mb-5'>
                        <label className="label">
                            <span className="label-text">Enter Poster Title : </span>
                        </label>
                        <input
                            onChange={(e) => handlePosterTitle(e)}
                            type="text"
                            placeholder="Enter Poster Title"
                            defaultValue={poster?.title}
                            className="px-3 py-2 border w-full rounded-md focus:outline-none"
                            required
                        />
                    </div>
                    <span className="label-text font-semibold text-xl">Upload : </span>
                    <label
                        htmlFor="upload-photo"
                        className="label flex flex-col justify-center items-center cursor-pointer bg-green-100 border border-black border-dashed rounded-lg p-14 mt-2"
                    >
                        <p className='font-semibold'>
                            Drag & Drop your File or <span className="text-primary">Browse</span>
                        </p>
                        <hr className="border-b border-black border-dashed w-full my-2" />

                        <div className="text-xl font-bold text-success mt-5 ">
                            {
                                imageStatus ?
                                    imageStatus
                                    :
                                    <div className='text-lg text-gray-500 font-normal flex justify-center flex-col items-center'>
                                        <ImUpload className='text-4xl text-gray-400' />
                                        <h2>Upload Poster Image</h2>
                                    </div>
                            }
                        </div>

                        {isLoading && (
                            <Dna
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                            />
                        )}
                    </label>
                    <input
                        onChange={handleImage}
                        type="file"
                        id="upload-photo"
                        className="px-3 py-2 border w-full rounded-md hidden"
                    />
                </div>
                <div onClick={ImageUpload} className="w-full flex justify-center">
                    <input
                        type="submit"
                        value="Update"
                        className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-2 w-full rounded-md text-white "
                    />
                </div>
            </div>

        </div>
    );
};

export default UpdateShareImage;