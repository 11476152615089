import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';

const ManageSubCategory = () => {
    const [subCategories, setSubCategories] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    // Delete Category-------------------
    const onSubmit = (data) => {
        const deleteSubCategory = {
            id: data?.subCategory,
        };

        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                const url = 'https://api.nagoriktv.com/sub-category/';
                axios.delete(url, { data: deleteSubCategory })
                    .then((res) => {
                        // console.log(res);
                        Swal.fire('Good job!', 'Sub Category is Deleted Successful!', 'success');
                        reset();
                        setRefresh(!refresh);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });


    };

    // get all Subcategories -------------------------
    useEffect(() => {

        axios('https://api.nagoriktv.com/sub-category/')
            .then((res) => {
                // console.log(res.data);
                setSubCategories(res?.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [refresh]);



    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-2xl font-bold flex gap-2 items-center">
                    <FaTrashAlt className="text-2xl" />
                    <span>Delete Sub Category</span>
                </h2>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
            >
                <div>
                    <h2 className="text-2xl font-semibold">Delete Sub Category</h2>
                    <hr className="mt-2" />
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text font-semibold">Select Sub Category : </span>
                    </label>
                    <select
                        className="px-3 py-3 border w-full rounded-md"
                        {...register('subCategory', { required: 'Please Select SubCategory' })}
                    >
                        <option value="">Select Sub Category</option>
                        {subCategories?.map((subCategory, i) => (
                            <option key={i} value={subCategory?.id}>
                                {subCategory?.sub_category_name}
                            </option>
                        ))}
                    </select>
                    {errors.category && (
                        <span className="text-error">{errors.category.message}</span>
                    )}
                </div>

                <div className="w-full flex justify-center">
                    <input
                        type="submit"
                        value="Delete"
                        className="bg-error hover:bg-red-600 duration-500 cursor-pointer py-3 w-full rounded-md text-white "
                    />
                </div>
            </form>
        </div>
    );
};

export default ManageSubCategory;