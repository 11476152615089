import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { MdRateReview } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ManageArticle = () => {
  const [articles, setArticles] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [lastpage, setLastPage] = useState(false);

  const handlePrev = () => {
    if (page === 1) {
      setPage(1);
      return;
    }
    setPage(page - 1);
  };

  // const handleNext = () => {
  //   if (articles?.length === 10) {
  //     console.log(articles?.length);
  //     // console.log(page);
  //     setPage(page + 1);
  //     return;
  //   }
  //   console.log(page);
  //   setPage(page);
  // };

  const handleNext = () => {
    if (lastpage) {
      // if (articles?.length < 10) {
      setPage(page);
      return;
    }
    setPage(page + 1);
  };

  // Get all Articles---------------------------
  useEffect(() => {
    const url = `https://api.nagoriktv.com/articlewithallstatus/${page}/10`
    axios(url)
      // axios(`https://api.nagoriktv.com/articlecount/${page}/10`)
      .then((res) => {
        setLastPage(false);
        setArticles(res?.data);
        // console.log(res.data);
      })
      .catch((error) => {
        setLastPage(!lastpage);
        console.log(error);
      });
  }, [refresh, page]);

  // Delete Article-----------------------------
  const deleteArticle = (id) => {
    Swal.fire({
      title: 'Are you sure to Delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://api.nagoriktv.com/articleapi/${id}`)
          .then((res) => {
            // console.log( res.data );
            setRefresh(!refresh);
            if (res?.data.success) {
              Swal.fire('Deleted!', 'Article Deleted Successfully!', 'success');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <MdRateReview className="text-3xl" />
          <span>Manage Article</span>
        </h2>
      </div>

      {/* All Article-------------- */}
      <div className="bg-white m-5 rounded-md p-8">
        <h1 className="text-2xl font-semibold">Articles List</h1>
        <hr className="mb-4" />
        <div className="overflow-x-auto rounded-lg">
          <table className="table table-zebra ">
            {/* head */}
            <thead>
              <tr className="text-black text-lg bg-slate-200 ">
                <th>Sl</th>
                <th>New Title</th>
                <th>Status</th>
                <th className="flex justify-end mr-10">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              {articles?.map((article, i) => (
                <tr key={i}>
                  <th>
                    {page === 1 ? (
                      <>{page === 1 || i + 1 === 10 ? '' : ''}</>
                    ) : (
                      <>{page === 1 || i + 1 === 10 ? page : page - 1}</>
                    )}

                    {i + 1 === 10 && page <= 1 ? page : ''}

                    {i + 1 === 10 ? 0 : i + 1}
                  </th>
                  <td className="font-semibold">{article?.title}</td>
                  <td className="font-semibold">{article?.status}</td>

                  <td className="flex gap-1 justify-end">
                    {/* <Link className="btn btn-sm btn-success text-white"> */}
                    <Link
                      to={`/updatearticle/${article?.article_id}`}
                      className="btn btn-sm btn-success text-white"
                    >
                      <FaEdit />
                      Update
                    </Link>
                    <button
                      onClick={() => deleteArticle(article?.article_id)}
                      className="btn btn-sm btn-error text-white"
                    >
                      <FaTrashAlt /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full mx-auto text-center mt-5">
          <button onClick={handlePrev} className="btn btn-sm mr-10 btn-accent">
            Prev
          </button>
          <button onClick={handleNext} className="btn btn-sm  btn-accent">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageArticle;
