import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaCogs, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ManageShareImage = () => {
    const [images, setImages] = useState([]);
    const [refresh, setRefresh] = useState(false);

    // Get all Poster Images--------
    useEffect(() => {
        const url = 'https://api.nagoriktv.com/get-all-banner-image/'
        axios(url)
            .then(res => {
                // console.log(res);
                setImages(res?.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [refresh])


    // Delete Image--------
    const headlineDelete = (image_id) => {

        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        })
            .then((result) => {
                if (result.isConfirmed) {

                    const url = 'https://api.nagoriktv.com/get-all-banner-image/'
                    const id = { id: image_id }
                    axios.delete(url, { data: id })
                        .then(res => {
                            // console.log(res);
                            Swal.fire('Deleted!', `${res?.data?.message}`, 'success');
                            setRefresh(!refresh);
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            });

    }



    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-2xl font-bold flex gap-2 items-center">
                    <FaCogs className="text-2xl" />
                    <span>Manage Share Poster</span>
                </h2>
            </div>

            {/* Manage Scroll News table--------------------- */}

            <div className="bg-white m-5 rounded-md p-8">
                <h1 className="text-xl font-semibold">Poster Lists</h1>
                <hr className="mb-4" />
                <div className="border rounded-md p-3 w-full">
                    <div className='w-full flex flex-col gap-5'>
                        {
                            images?.map((image, i) =>
                                <div key={i}>
                                    <div className='border p-3 rounded-md flex items-center justify-between gap-3'>
                                        <PhotoProvider>
                                            <PhotoView key={i} src={image?.image_url}>
                                                <img src={image?.image_url} alt="" className='w-20 cursor-pointer' />
                                            </PhotoView>
                                        </PhotoProvider>
                                        <p>{image?.title ? image?.title : 'No title'}</p>
                                        <div className='flex gap-2 items-center'>
                                            {/* <FaEdit /> */}
                                            <Link
                                                to={`/updatePoster/${image?.id}`}
                                                className="btn btn-sm btn-success text-white"
                                            >
                                                <FaEdit /> Edit
                                            </Link>
                                            <button
                                                onClick={() => headlineDelete(image?.id)}
                                                className="btn btn-sm btn-error text-white"
                                            >
                                                <FaTrashAlt /> Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

            </div>

        </div>
    );
};

export default ManageShareImage;