import { createBrowserRouter } from 'react-router-dom';
import Main from '../../Layouts/Main/Main';
import Home from '../../pages/Home/Home';
import AddUser from '../../pages/Users/AddUser/AddUser';
import ManageUsers from '../../pages/Users/ManageUsers/ManageUsers';
import AddArticle from '../../pages/Article/AddArticle/AddArticle';
import ManageAricle from '../../pages/Article/ManageArticle/ManageArticle';
import AddVideos from '../../pages/Videos/AddVideos/AddVideos';
import Login from '../../Shared/Login/Login';
import AddPoll from '../../pages/Polls/AddPoll/AddPoll';
import AddTag from '../../pages/Tags/AddTag';
import DeleteTags from '../../pages/Tags/DeleteTags/DeleteTags';
import ManageVideos from '../../pages/Videos/ManageVideos/ManageVideos';
import AddCategory from '../../pages/Category/AddCategory/AddCategory';
import ManageCategory from '../../pages/Category/ManageCategory/ManageCategory';
import UpdateArticle from '../../pages/Article/UpdateArticle/UpdateArticle';
import UpdateUser from '../../pages/Users/UpdateUser/UpdateUser';
import UpdateVideo from '../../pages/Videos/UpdateVideo/UpdateVideo';
import ManagePolls from '../../pages/Polls/ManagePolls/ManagePolls';
import AddScrollNews from '../../pages/Highlight News/AddScrollNews/AddScrollNews';
import ManageScrollNews from '../../pages/Highlight News/ManageScrollNews/ManageScrollNews';
import UploadShareImage from '../../pages/UploadShareImage/UploadShareImage';
import ManageAllComments from '../../pages/ManageAllComments/ManageAllComments';
import FrontEndUsers from '../../pages/FrontEndUsers/FrontEndUsers';
import AddSubCategory from '../../pages/Category/AddSubCategory/AddSubCategory';
import ManageSubCategory from '../../pages/Category/ManageSubCategory/ManageSubCategory';
import UpdatePoll from '../../pages/Polls/UpdatePoll/UpdatePoll';
import UpdateHighlightnews from '../../pages/Highlight News/UpdateHighlightnews/UpdateHighlightnews';
import UpdateTag from '../../pages/Tags/UpdateTag/UpdateTag';
import ManageShareImage from '../../pages/ManageShareImage/ManageShareImage';
import UpdateShareImage from '../../pages/UploadShareImage/UpdateShareImage/UpdateShareImage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Main></Main>,
    children: [
      {
        path: '/',
        element: <Home></Home>,
      },
      {
        path: '/adduser',
        element: <AddUser></AddUser>,
      },
      {
        path: '/manageuser',
        element: <ManageUsers></ManageUsers>,
      },
      {
        path: '/updateuser/:id',
        element: <UpdateUser></UpdateUser>,
      },
      {
        path: '/addarticle',
        element: <AddArticle></AddArticle>,
      },
      {
        path: '/reviewarticle',
        element: <ManageAricle></ManageAricle>,
      },
      {
        path: '/updatearticle/:id',
        element: <UpdateArticle></UpdateArticle>,
      },
      {
        path: '/addvideos',
        element: <AddVideos></AddVideos>,
      },
      {
        path: '/managevideos',
        element: <ManageVideos></ManageVideos>,
      },
      {
        path: '/updatevideo/:id',
        element: <UpdateVideo></UpdateVideo>,
      },
      {
        path: '/login',
        element: <Login></Login>,
      },
      {
        path: '/addpoll',
        element: <AddPoll></AddPoll>,
      },
      {
        path: 'updatepoll/:id',
        element: <UpdatePoll />
      },
      {
        path: '/managepoll',
        element: <ManagePolls></ManagePolls>,
      },
      {
        path: '/addtag',
        element: <AddTag></AddTag>,
      },
      {
        path: '/deletetag',
        element: <DeleteTags></DeleteTags>,
      },
      {
        path: '/updatetag/:id',
        element: <UpdateTag />
      },
      {
        path: '/addcategory',
        element: <AddCategory></AddCategory>,
      },
      {
        path: '/managecategory',
        element: <ManageCategory></ManageCategory>,
      },
      {
        path: '/addsubcategory',
        element: <AddSubCategory></AddSubCategory>
      },
      {
        path: '/managesubcategory',
        element: <ManageSubCategory></ManageSubCategory>,
      },
      {
        path: '/addscroll',
        element: <AddScrollNews />
      },
      {
        path: '/updatehighlite/:id/:title',
        element: <UpdateHighlightnews />
      },
      {
        path: '/managescroll',
        element: <ManageScrollNews />
      },
      {
        path: '/shareimage',
        element: <UploadShareImage />
      },
      {
        path: '/manageshareimage',
        element: <ManageShareImage />
      },
      {
        path: '/updatePoster/:id',
        element: <UpdateShareImage />
      },
      {
        path: '/managecomment',
        element: <ManageAllComments />
      },
      {
        path: '/frontendusers',
        element: <FrontEndUsers />
      }
    ],
  },
]);
