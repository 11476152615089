import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TfiWrite } from 'react-icons/tfi';
import Swal from 'sweetalert2';

const AddVideos = () => {
  const [categories, setCategories] = useState([]);
  const [videoUrl, setVideoUrl] = useState();
  const [selectCategoryId, setSelectCategoryId] = useState('8')
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Submit video Data-------------
  const onSubmit = (data) => {
    // console.log(data);
    const video = {
      video_url: videoUrl && videoUrl,
      video_title: data?.title,
      video_meta: data?.metadata,
    };

    axios
      .post('https://api.nagoriktv.com/videos/', video)
      .then((res) => {
        Swal.fire('Good job!', 'Video is created Successfully!', 'success');
        reset();
        setVideoUrl()
        const videoCategory = {
          video_id: res.data?.new_video_id,
          // category_id: '8',
          category_id: selectCategoryId,
        };
        axios
          .post(
            'https://api.nagoriktv.com/video-categories/',
            videoCategory
          )
          .then((res) => {
            // console.log( res );
            if (res.data === 'Video category was created.') {
              Swal.fire(
                'Good job!',
                'Video is created Successfully!',
                'success'
              );
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // handle video url--------
  const hadleVideoUrl = (e) => {
    setVideoUrl(e?.target?.value);
  }

  // get all categories -------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/categories/')
      .then((res) => {
        // console.log( res.data );
        setCategories(res?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <TfiWrite className="text-2xl" />
          <span>Add Videos</span>
        </h2>
      </div>

      {/* Form --------------------- */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
      >
        <div>
          <h1 className="text-2xl font-semibold">Add Videos</h1>
          <hr className="mt-2" />
        </div>

        {/* video Title----------- */}
        <div>
          <label className="label">
            <span className="label-text">Video Title : </span>
          </label>
          <input
            type="text"
            placeholder="Title Here"
            className="px-3 py-2 border w-full rounded-md"
            {...register('title', { required: 'Title is required' })}
          />
          {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
        </div>

        {/* video url------------------- */}
        <div>
          <label className="label">
            <span className="label-text">Video URL : </span>
          </label>
          <input
            onChange={(e) => hadleVideoUrl(e)}
            type="text"
            placeholder="Video URL Here"
            className="px-3 py-2 border w-full rounded-md"
            required
          />
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Video Category : </span>
          </label>
          <select
            className="px-3 py-2 border w-full rounded-md"
            // {...register('category_id', { required: false })}
            onChange={() => setSelectCategoryId('8')}
            disabled
          >
            {categories?.map((category) => (
              <option key={category?.category_id} value='8'>
                নাগরিক ভাইরাল
              </option>
            ))}
          </select>
          {errors.category_id && (
            <span className="text-error">{errors.category_id.message}</span>
          )}
        </div>


        {
          videoUrl &&
          <img
            src={`https://img.youtube.com/vi/${videoUrl?.split('=').pop()}/maxresdefault.jpg`}
            alt=""
            className="w-full h-full rounded-md object-cover"
          />
        }

        <div className="w-full flex justify-center">
          <input
            type="submit"
            value="Save"
            className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-2 w-full rounded-md text-white "
          />
        </div>
      </form>
    </div>
  );
};

export default AddVideos;
