import React, { useContext, useState } from 'react';
import img from '../../Assets/images/login.jpg';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { userContext } from '../../Context/UserContext/UserProvider/UserProvider';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';

const Login = () => {
  const { setUser } = useContext(userContext);
  const navigate = useNavigate('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (user) => {
    // console.log(user);
    axios
      .post('https://api.nagoriktv.com/login/', user)

      .then((res) => {
        if (!res?.data?.status) {
          setError(res?.data?.message);
        }
        localStorage.setItem('userId', res?.data?.user_id);

        axios(`https://api.nagoriktv.com/users/${res?.data?.user_id}`)
          .then((res) => {
            setUser(res?.data);
            navigate('/');
          })
          .catch((error) => {
            console.log(error);
          });

        // console.log(res?.data?.user_id);
        // setUser(res?.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Password Show / hidden-----------------------
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  // Forgote Password---------------------
  const handleForgotPassword = (e) => {
    e.preventDefault();
    const form = e.target;
    const value = form.email.value;

    const email = {
      email: value,
    };

    axios
      .post('https://api.nagoriktv.com/forgot-password', email)
      .then((res) => {
        if (res?.data?.success) {
          toast.success('Please Check You Email !');
          form.reset();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="py-8">
      <Toaster position="top-right" reverseOrder={false} />;
      {/* <div className='px-5 py-3 shadow-md bg-white'>
                <h2 className='text-2xl font-bold flex gap-2 items-center'>
                    <span>Login</span>
                </h2>
            </div> */}
      <h2 className="text-4xl font-bold text-center underline mb-5 ">Login</h2>
      {/* Login form.......................... */}
      <div className="flex bg-white shadow-lg w-2/3 mx-auto rounded-lg">
        <div className="w-1/2 rounded-l-lg">
          <img src={img} alt="" className="rounded-l-lg w-full object-cover" />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-1/2 m-5 flex gap-5 flex-col justify-center items-center "
        >
          <h1 className="text-2xl font-bold ">Login</h1>
          <hr className="border-b-gray-400 border w-full" />

          {/* Email-------------- */}
          <div className="w-full">
            <label className="label">
              <span className="label-text">Enter User Name : </span>
            </label>
            <input
              type="text"
              placeholder="Enter User Name"
              className="px-3 py-2 border w-full rounded-md"
              {...register('username', {
                required: 'Please Enter Your username!',
              })}
            />
            {errors.username && (
              <span className="text-error">{errors.username.message}</span>
            )}
          </div>

          {/* Password--------------- */}
          <div className="w-full ">
            <label className="label">
              <span className="label-text">Password : </span>
            </label>

            <div className="relative">
              {showPassword ? (
                <FaEye
                  onClick={() => handlePassword()}
                  className="absolute right-4 top-3 text-lg cursor-pointer"
                />
              ) : (
                <FaEyeSlash
                  onClick={() => handlePassword()}
                  className="absolute right-4 top-3 text-lg cursor-pointer"
                />
              )}
              <input
                type={showPassword ? 'password' : 'text'}
                placeholder="Password"
                className="px-3 py-2 border w-full rounded-md"
                {...register('password', {
                  required: 'Enter Your Valid Password',
                })}
              />
            </div>
            {errors.password && (
              <span className="text-error">{errors.password.message}</span>
            )}
          </div>

          {error && <p className="text-error">{error}</p>}

          <div className="w-full">
            <div
              onClick={() => window.my_modal_2.showModal()}
              className="font-semibold text-right cursor-pointer hover:text-[#158488] duration-500"
            >
              Forgot password?
            </div>
          </div>
          <input
            type="submit"
            value="Login"
            className="w-full bg-[#158488] hover:bg-[#0c5255] duration-500 py-2 rounded-md text-white cursor-pointer"
          />
        </form>
      </div>
      <dialog id="my_modal_2" className="modal z-0">
        <form
          onSubmit={(e) => handleForgotPassword(e)}
          method="dialog"
          className="modal-box"
        >
          <h3 className="font-bold text-lg">Please Enter your Email Id</h3>
          <input
            type="email"
            name="email"
            placeholder="example@gmail.com"
            className="px-3 py-2 border w-full rounded-md my-5"
            required
          />
          {/* <button className="w-full bg-[#158488] hover:bg-[#0c5255] duration-500 py-2 rounded-md text-white cursor-pointer">
            Submit
          </button> */}
          <input
            type="submit"
            value="Login"
            className="w-full bg-[#158488] hover:bg-[#0c5255] duration-500 py-2 rounded-md text-white cursor-pointer"
          />
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
};

export default Login;
