import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash, FaUserPlus } from 'react-icons/fa';
import Swal from 'sweetalert2';

const AddUser = () => {
  const [showPassword, setShowPassword] = useState(true);

  const { register, handleSubmit, reset, formState: { errors }, } = useForm();

  const onSubmit = (data) => {
    const user = {
      username: data?.name,
      email: data?.email,
      password: data?.password,
      role: data?.role,
    };

    const url = 'https://api.nagoriktv.com/users/';
    axios
      .post(url, user)
      .then((res) => {
        // console.log(res);
        Swal.fire('Good job!', 'User is created Successful!', 'success');
        reset();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire('Ooops!', 'User already created!', 'error');
      });
  };

  const handlePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <FaUserPlus />
          <span>Add Users</span>
        </h2>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-8 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
      >
        <div>
          <h1 className="text-2xl font-semibold">Add User</h1>
          <hr className="mt-2" />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Enter User Name : </span>
          </label>
          <input
            type="text"
            placeholder="Enter User Name"
            className="px-3 py-2 border w-full rounded-md"
            {...register('name', { required: 'User_Name is required' })}
          />
          {errors.name && <span className='text-error'>{errors.name.message}</span>}
        </div>
        <div>
          <label className="label">
            <span className="label-text">Enter Email : </span>
          </label>
          <input
            type="email"
            placeholder="Enter Email"
            className="px-3 py-2 border w-full rounded-md"
            {...register('email', { required: 'Please Enter Your Email!' })}
          />
          {errors.email && <span className='text-error'>{errors.email.message}</span>}
        </div>
        <div>
          <label className="label">
            <span className="label-text">Password : </span>
          </label>

          <div className='relative'>
            {
              showPassword ? <FaEye onClick={() => handlePassword()} className='absolute right-4 top-3 text-lg cursor-pointer' />
                :
                <FaEyeSlash onClick={() => handlePassword()} className='absolute right-4 top-3 text-lg cursor-pointer' />
            }
            <input
              type={showPassword ? "password" : "text"}
              placeholder="Password"
              className="px-3 py-2 border w-full rounded-md"
              {...register('password', { required: 'Enter Your Valid Password' })}
            />
          </div>
          {errors.password && <span className='text-error'>{errors.password.message}</span>}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">User Role : </span>
          </label>
          <select
            className="px-3 py-2 border w-full rounded-md"
            {...register('role', { required: 'Please Select Role' })}
          >
            <option value="">Select Role</option>
            <option value="admin">admin</option>
            <option value="reviewer">reviewer</option>
            <option value="editor">editor</option>
          </select>
          {errors.role && (
            <span className="text-error">{errors.role.message}</span>
          )}
        </div>
        <div className="w-full flex justify-center">
          <input
            type="submit"
            value="Save"
            className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-2 w-full rounded-md text-white "
          />
        </div>
      </form>
    </div>
  );
};

export default AddUser;
