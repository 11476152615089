import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaScroll, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ManageScrollNews = () => {
    const [newses, setNewses] = useState([]);
    const [refresh, setRefresh] = useState(false)

    const url = 'https://api.nagoriktv.com/news-scroll';
    useEffect(() => {
        axios(url)
            .then(res => {
                setNewses(res?.data)
                // console.log(res);
            })
            .catch(error => {
                console.log(error);
            })
    }, [refresh])

    // delete headlineDelete------------------
    const headlineDelete = (id) => {

        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        })
            .then((result) => {
                if (result.isConfirmed) {

                    const url = 'https://api.nagoriktv.com/news-scroll'
                    const headline = {
                        id: id
                    }
                    axios.delete(url, { data: headline })
                        .then(res => {
                            // console.log(res);
                            Swal.fire('Deleted!', `${res?.data?.message}`, 'success');
                            setRefresh(!refresh);
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            });



    }

    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-2xl font-bold flex gap-2 items-center">
                    <FaScroll className="text-2xl" />
                    <span>Manage Highlight News</span>
                </h2>
            </div>

            {/* Manage Scroll News table--------------------- */}

            <div className="bg-white m-5 rounded-md p-8">
                <h1 className="text-xl font-semibold">Highlight Lists</h1>
                <hr className="mb-4" />
                <div className="overflow-x-auto rounded-lg">
                    <table className="table table-zebra">
                        {/* head */}
                        <thead>
                            <tr className="text-black text-lg bg-slate-200">
                                <th>Sl</th>
                                <th>News Headline</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row 1 */}
                            {newses?.map((news, i) => (
                                <tr key={i}>
                                    <th>{i + 1}</th>
                                    <td className="font-semibold">{news?.headline}</td>
                                    <td className="flex gap-1">
                                        <Link
                                            to={`/updatehighlite/${news?.id}/${news?.headline}`}
                                            className="btn btn-sm btn-success text-white"
                                        >
                                            <FaEdit /> Edit
                                        </Link>

                                        <button
                                            onClick={() => headlineDelete(news?.id)}
                                            className="btn btn-sm btn-error text-white"
                                        >
                                            <FaTrashAlt /> Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    );
};

export default ManageScrollNews;