import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';

export const userContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // Add isAdmin state

  useEffect(() => {
    const id = localStorage.getItem('userId');

    if (id) {
      axios(`https://api.nagoriktv.com/users/${id}`)
        .then((res) => {
          // console.log(res?.data);
          setUser(res?.data);

          // Check the user's role and set isAdmin accordingly
          if (res?.data?.role === 'admin') {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const userInfo = { setUser, user, isAdmin }; // Include isAdmin in userInfo
  return (
    <userContext.Provider value={userInfo}>{children}</userContext.Provider>
  );
};

export default UserProvider;
