import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaRebel, FaRedo, FaTrashAlt, FaUser, FaUsersSlash } from 'react-icons/fa';
import Swal from 'sweetalert2';

const FrontEndUsers = () => {
    const [frontendUsers, setFrontendUsers] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false)

    // Get all users-------------
    const url = 'https://api.nagoriktv.com/front-users/';
    useEffect(() => {
        setLoading(true)
        axios(url)
            .then(res => {
                setFrontendUsers(res?.data)
                // console.log(res?.data);
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })
    }, [refresh])

    // delete headlineDelete------------------
    const headlineDelete = (id) => {

        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        })
            .then((result) => {
                if (result.isConfirmed) {

                    const url = 'https://api.nagoriktv.com/front-users/'
                    const user = {
                        id: id
                    }
                    axios.delete(url, { data: user })
                        .then(res => {
                            // console.log(res);
                            Swal.fire('Deleted!', `${res?.data?.message}`, 'success');
                            setRefresh(!refresh);
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            });



    }




    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-xl font-bold flex gap-2 items-center">
                    <FaUsersSlash className="text-2xl" />
                    <span>All Front-End Users</span>
                </h2>
            </div>

            {/* All Users-------------- */}
            <div className="bg-white m-5 rounded-md p-8">
                <div className='flex justify-between'>
                    <h1 className="text-xl font-semibold">Front-End users Lists</h1>
                    <div onClick={() => setRefresh(!refresh)} className='border-2 rounded-full p-2 cursor-pointer'>
                        {
                            loading ?
                                <FaRedo className='text-xl animate-spin' />
                                :
                                <FaRedo className='text-xl' />

                        }
                    </div>
                </div>
                <hr className="mb-4 mt-2" />
                <div className="overflow-x-auto rounded-lg">
                    <table className="table table-zebra">
                        {/* head */}
                        <thead>
                            <tr className="text-black text-lg bg-slate-200">
                                <th>Sl</th>
                                {/* <th>User_Image</th> */}
                                <th>Users_Name</th>
                                <th>Users_Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* row 1 */}
                            {frontendUsers?.map((user, i) => (
                                <tr key={i}>
                                    <th>{i + 1}</th>
                                    {/* <td className="font-semibold">
                                        <img src={user?.frontuser_image} alt="" className='w-16 h-16 p-1 rounded-full object-cover border-2' />
                                    </td> */}
                                    <td className="font-semibold flex gap-2 items-center">
                                        {
                                            user?.frontuser_image ?
                                                <img src={user?.frontuser_image} alt="" className='w-14 h-14 p-1 rounded-full object-cover border-2' />
                                                :
                                                <FaUser className='w-14 h-14 p-1 rounded-full object-cover border-2 text-gray-500' />
                                        }
                                        {user?.username}
                                    </td>
                                    <td className="font-semibold">{user?.email}</td>
                                    <td className="flex gap-1 ">

                                        <button
                                            onClick={() => headlineDelete(user?.id)}
                                            className="btn btn-sm btn-error text-white"
                                        >
                                            <FaTrashAlt /> Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>


        </div>
    );
};

export default FrontEndUsers;