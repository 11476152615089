import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaTag } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const UpdateTag = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { id } = useParams();
    const [tag, setTag] = useState();
    const [refresh, setRefresh] = useState(true)

    // Get single Tag-------------------
    useEffect(() => {
        const url = `https://api.nagoriktv.com/tags/${id}`;
        axios(url)
            .then(res => {
                // console.log(res);
                setTag(res?.data)
            })
            .catch(error => {
                // console.log(error);
            })
    }, [id, refresh])

    // update tag---------
    const onSubmit = data => {
        const updateTag = {
            tag_id: id,
            name: data?.tag ? data?.tag : tag?.name
        }
        const url = 'https://api.nagoriktv.com/tags';

        axios.put(url, updateTag)
            .then(res => {
                if (res?.data?.message === 'Tag updated.') {
                    Swal.fire(
                        'Good job!',
                        'Tag is Update Successful!',
                        'success'
                    )
                    reset();
                    setRefresh(!refresh)
                }
            })
            .catch((error) => {
                // console.log( error );
                Swal.fire(
                    'Ooops!',
                    'Tag already created!',
                    'error'
                )

            });
    }

    return (
        <div>
            <div className='px-5 py-3 shadow-md bg-white'>
                <h2 className='text-2xl font-bold flex gap-2 items-center'>
                    <FaTag className='text-2xl' />
                    <span>Update Tag</span>
                </h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className='p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center'>
                <div>
                    <h2 className='text-2xl font-semibold'>Update Tag</h2>
                    <hr className='mt-2' />
                </div>
                <div>
                    <label className="label">
                        <span className="label-text">Tag Name : </span>
                    </label>
                    <input type="text" defaultValue={tag?.name} placeholder="Enter Tag Name" className="px-3 py-3 border w-full rounded-md" {...register("tag", { required: 'Tag Name is required' })} />
                    {errors.tag && <span className='text-error'>{errors.tag.message}</span>}
                </div>

                <div className='w-full flex justify-center'>
                    <input type="submit" value="Update" className='bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white ' />
                </div>
            </form>

        </div>
    );
};

export default UpdateTag;