import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdCategory } from 'react-icons/md';
import Swal from 'sweetalert2';

const AddSubCategory = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [categories, setCategories] = useState([]);

    const onSubmit = data => {

        const subcategory = {
            sub_category_name: data?.subcategory,
            category_id: data?.category_id
        }

        const url = 'https://api.nagoriktv.com/sub-category/'
        axios.post(url, subcategory)
            .then(res => {
                // console.log( res );
                Swal.fire(
                    'Good job!',
                    'Sub Category is created Successful!',
                    'success'
                )
                reset();
            })
            .catch(error => {
                console.log(error);
            })
    }


    // get all categories -------------------------
    useEffect(() => {
        axios('https://api.nagoriktv.com/categories/').then((res) => {
            // console.log( res.data );
            setCategories(res?.data);
        });
    }, []);


    return (
        <div>
            <div className='px-5 py-3 shadow-md bg-white'>
                <h2 className='text-2xl font-bold flex gap-2 items-center'>
                    <MdCategory className='text-2xl' />
                    <span>Add Sub Category</span>
                </h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className='p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center'>
                <div>
                    <h2 className='text-2xl font-semibold'>Add Sub Category</h2>
                    <hr className='mt-2' />
                </div>
                <div className='grid grid-cols-2 gap-5'>
                    <div>
                        <label className="label">
                            <span className="label-text font-semibold">Sub Category Name : </span>
                        </label>
                        <input type="text" placeholder="Enter Sub Category Name" className="px-3 py-3 border w-full rounded-md" {...register("subcategory", { required: 'Category Name is required' })} />
                        {errors.name && <span className='text-error'>{errors.name.message}</span>}
                    </div>
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text font-semibold">Select Category : </span>
                        </label>
                        <select
                            className="px-3 py-3 border w-full rounded-md"
                            {...register('category_id', { required: 'Please Select Category' })}
                        >
                            <option value="">Select Category</option>
                            {categories?.map((category) => (
                                <option key={category?.category_id} value={category?.category_id}>
                                    {category?.name}
                                </option>
                            ))}
                        </select>
                        {errors.category && (
                            <span className="text-error">{errors.category.message}</span>
                        )}
                    </div>
                </div>

                <div className='w-full flex justify-center'>
                    <input type="submit" value="Save" className='bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white ' />
                </div>
            </form>

        </div>
    );
};

export default AddSubCategory;