import React, { useEffect, useState } from 'react';
import { FaPoll, FaUsers, FaUsersCog, FaVideo } from 'react-icons/fa';
import { RiFileList3Fill } from 'react-icons/ri';
import { AiOutlineNumber } from "react-icons/ai";
import axios from 'axios';


const Home = () => {
    const [counts, setCounts] = useState();
    const [frontendUsers, setFrontendUsers] = useState([]);

    const url = 'https://api.nagoriktv.com/count/';
    useEffect(() => {
        axios(url)
            .then(res => {
                setCounts(res?.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    // Get all Frontend users-------------
    useEffect(() => {
        const url = 'https://api.nagoriktv.com/front-users/';
        axios(url)
            .then(res => {
                setFrontendUsers(res?.data)
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    const users = [
        {
            name: 'Admin Users',
            icon: <FaUsers className='text-[#7C3AED]' />,
            total: counts?.user_count,
        },
        {
            name: 'Clients Users',
            icon: <FaUsersCog className='text-[#296827]' />,
            total: frontendUsers?.length,
        },
        {
            name: 'Article',
            icon: <RiFileList3Fill className='text-[#EA580C]' />,
            total: counts?.article_count,
        },
        {
            name: 'Video',
            icon: <FaVideo className='text-[#059669]' />,
            total: counts?.video_count,
        },
        {
            name: 'Polls',
            icon: <FaPoll className='text-[#9333EA]' />,
            total: counts?.poll_count,
        },

    ]

    return (
        <div className=''>
            <div className='px-5 py-3 shadow-sm bg-white'>
                <h2 className='text-2xl font-bold flex gap-2 items-center'>
                    <span>Dashboard</span>
                </h2>
            </div>

            <div className='m-8 grid grid-cols-4 gap-5'>
                {
                    users?.map((user, i) =>
                        // console.log(user?.color)
                        < div key={i} className='bg-white p-3 rounded-md flex gap-3 items-center' >
                            <div className='text-4xl'>
                                {user?.icon}
                            </div>
                            <div>
                                <h2 className='font-semibold'>Total: {user?.name}</h2>
                                <h3 className='text-xl font-bold flex gap-1 items-center'><AiOutlineNumber className='text-2xl' /> {user?.total}</h3>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Home;