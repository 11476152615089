import { Editor } from 'primereact/editor';
import React, { useState } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";


const TextEditor = ({ text, setText, handleImage }) => {
    const [textColor, setTextColor] = useState('#000000');
    const [textSize, setTextSize] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');

    const renderHeader = () => {
        // const handleImageUpload = (e) => {
        //     const getImages = e.target.files;
        //     console.log('hitted');
        //     console.log(getImages);
        // const url = prompt('Enter the URL of the image:');
        // if (url) {
        //   // Add the image to the editor content
        //   const updatedText = text + `<div><img src="${url}" alt="Uploaded Image" /></div>`;
        //   setText(updatedText);
        // }
        // };

        return (
            <span className="ql-formats flex items-center">
                <span className='z-50'>
                    <select className="ql-size z-50 " onChange={(e) => setTextSize(e.target.value)} value={textSize} aria-label="Text Size">
                        <option value="small"></option>
                        <option value="normal"></option>
                        <option value="large"></option>
                        <option value="huge"></option>
                    </select>
                </span>
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                {/* <button onClick={handleImage} className="ql-image" aria-label="Image"></button> */}

                <select className="ql-color" onChange={(e) => setTextColor(e.target.value)} value={textColor} aria-label="Text Color"></select>
                <select className="ql-background" onChange={(e) => setBackgroundColor(e.target.value)} value={backgroundColor} aria-label="Background Color"></select>
                {/* <span className=" flex items-center" aria-label="Image">
                    <label
                        htmlFor="upload-photo"
                        className="flex items-center"
                    >
                        <FaImage className='text-lg' />
                    </label>
                    <input
                        onChange={handleImage}
                        multiple
                        type="file"
                        id="upload-photo"
                        className="w-full rounded-md hidden"
                    />
                </span> */}
            </span>
        );
    };

    const header = renderHeader();

    return (
        <div className="card">
            <label className="label">
                <span className="label-text">News Details: </span>
            </label>
            {/* <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} headerTemplate={header} style={{ height: '320px' }} /> */}
                <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '350px' }} />
        </div>
    );
};

export default TextEditor;