import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaClipboardList } from 'react-icons/fa';
import Swal from 'sweetalert2';

const AddCategory = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = data => {
        const category = { name: data?.name }

        const url = 'https://api.nagoriktv.com/categories/'
        axios.post(url, category)
            .then(res => {
                // console.log( res );
                Swal.fire(
                    'Good job!',
                    'Category is created Successful!',
                    'success'
                )
                reset();
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (
        <div>
            <div className='px-5 py-3 shadow-md bg-white'>
                <h2 className='text-2xl font-bold flex gap-2 items-center'>
                    <FaClipboardList className='text-2xl' />
                    <span>Add Category</span>
                </h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className='p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center'>
                <div>
                    <h2 className='text-2xl font-semibold'>Add Category</h2>
                    <hr className='mt-2' />
                </div>
                <div>
                    <label className="label">
                        <span className="label-text">Category Name : </span>
                    </label>
                    <input type="text" placeholder="Enter Category Name" className="px-3 py-3 border w-full rounded-md" {...register("name", { required: 'Category Name is required' })} />
                    {errors.name && <span className='text-error'>{errors.name.message}</span>}
                </div>

                <div className='w-full flex justify-center'>
                    <input type="submit" value="Save" className='bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white ' />
                </div>
            </form>

        </div>
    );
};

export default AddCategory;