import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaCog, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const ManagePolls = () => {
  const [polls, setPolls] = useState([]);
  const [refresh, setRefresh] = useState(true);

  //   Get All Polls---------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/polls/')
      .then((res) => {
        // console.log(res?.data);
        setPolls(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);

  //   Delete Polls--------------------
  const pollDelete = (id) => {
    const deletePoll = {
      id: id,
    };

    Swal.fire({
      title: 'Are you sure to Delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then((result) => {
        if (result.isConfirmed) {
          const url = 'https://api.nagoriktv.com/polls/';
          axios
            .delete(url, { data: deletePoll })
            .then((res) => {
              if (res?.data) {
                Swal.fire('Deleted!', 'Poll Deleted Successfully!', 'success');
                setRefresh(!refresh);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  };

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <FaCog className="text-2xl" />
          <span>Manage Polls</span>
        </h2>
      </div>

      <div className="bg-white m-5 rounded-md p-8">
        <h1 className="text-2xl font-semibold">Polls List</h1>
        <hr className="mb-4" />
        <div className="overflow-x-auto rounded-lg">
          <table className="table table-zebra">
            {/* head */}
            <thead>
              <tr className="text-black text-lg bg-slate-200">
                <th>Sl</th>
                <th>Poll Question</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              {polls?.map((poll, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td className="font-semibold">{poll?.question}</td>
                  <td className="flex gap-1">
                    <Link
                      to={`/updatepoll/${poll?.id}`}
                      // onClick={() => videoUpdate(video?.video_id)}
                      className="btn btn-sm btn-success text-white"
                    >
                      <FaEdit /> Edit
                    </Link>
                    <button
                      onClick={() => pollDelete(poll?.id)}
                      className="btn btn-sm btn-error text-white"
                    >
                      <FaTrashAlt /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div className="w-full mx-auto text-center mt-5">
          <button onClick={handlePrev} className="btn btn-sm mr-10 btn-accent">
            Prev
          </button>
          <button onClick={handleNext} className="btn btn-sm  btn-accent">
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ManagePolls;
