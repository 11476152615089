import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaScroll } from 'react-icons/fa';
import Swal from 'sweetalert2';

const AddScrollNews = () => {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm();

    const onSubmit = (data) => {
        const news = {
            headline: data?.title
        }
        const url = 'https://api.nagoriktv.com/news-scroll'
        axios.post(url, news)
            .then(res => {
                // console.log(res);
                Swal.fire('Good job!', `${res?.data?.message}`, 'success');
                reset();
            })
            .catch(error => {
                console.log(error);
            })


    }

    return (
        <div>
            <div className="px-5 py-3 shadow-md bg-white">
                <h2 className="text-2xl font-bold flex gap-2 items-center">
                    <FaScroll className="text-2xl" />
                    <span>Add Highlight News</span>
                </h2>
            </div>

            {/* Form------------------- */}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
            >
                <div>
                    <h1 className="text-2xl font-semibold">Add Highlight News</h1>
                    <hr className="mt-2" />
                </div>

                {/* video Title----------- */}
                <div>
                    <label className="label">
                        <span className="label-text">Enter News Title : </span>
                    </label>
                    <input
                        type="text"
                        placeholder="Enter News Title"
                        className="px-3 py-2 border w-full rounded-md"
                        {...register('title', { required: 'Enter News Title' })}
                    />
                    {errors.name && (
                        <span className="text-error">{errors.name.message}</span>
                    )}
                </div>


                <div className="w-full flex justify-center">
                    <input
                        type="submit"
                        value="Save"
                        className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-2 w-full rounded-md text-white "
                    />
                </div>
            </form>


        </div>
    );
};

export default AddScrollNews;