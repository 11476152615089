import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const DeleteTags = () => {
  const [tags, setTags] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // Get All Tags--------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/tags/').then((res) => {
      // console.log( res );
      setTags(res?.data);
    });
  }, [refresh]);

  // Delete Tags------------------
  const handleTag = (id) => {
    const tag = {
      tag_id: id,
    };
    // console.log(tag);

    Swal.fire({
      title: 'Are you sure to Delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete('https://api.nagoriktv.com/tags/', { data: tag })
          .then((res) => {
            // console.log(res);
            Swal.fire('Good job!', 'Tag Deleted Successful!', 'success');
            setRefresh(!refresh);
          })
          .catch((error) => {
            // console.log( error );
            Swal.fire('Ooops!', 'Something Wrong!', 'error');
          });
      }
    });

  };

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <FaTrashAlt className="text-2xl" />
          <span>Delete Tag</span>
        </h2>
      </div>

      {/* Tag List-------------------------- */}

      <div className="bg-white m-5 rounded-md p-8">
        <h1 className="text-2xl font-semibold">Users List</h1>
        <hr className="mb-4" />
        <div className="overflow-x-auto rounded-lg">
          <table className="table table-zebra">
            {/* head */}
            <thead>
              <tr className="text-black text-lg bg-slate-200">
                <th>Sl</th>
                <th>Tag Name</th>
                {/* <th>Email</th> */}
                {/* <th>Password</th> */}
                {/* <th>Role</th> */}
                <th className='flex justify-end mr-20'>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* row 1 */}
              {tags?.map((tag, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td className="text-lg font-semibold">{tag?.name}</td>
                  {/* <td>{ user?.email }</td> */}
                  {/* <td>Blue</td> */}
                  {/* <td>{ user?.role }</td> */}
                  <td className="flex gap-3 justify-end mr-28'">
                    <Link
                      to={`/updatetag/${tag?.tag_id}`}
                      className="btn btn-sm btn-success text-white"
                    >
                      <FaEdit /> Edit
                    </Link>
                    <button
                      onClick={() => handleTag(tag?.tag_id)}
                      className="btn btn-sm btn-error text-white"
                    >
                      <FaTrashAlt /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DeleteTags;
