import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaTag } from 'react-icons/fa';
import Swal from 'sweetalert2';

const AddTag = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = data => {
        const tag = { name: data?.tag }

        axios.post('https://api.nagoriktv.com/tags/', tag)
            .then(res => {
                // console.log( res.data );
                if (res?.data?.message === 'Tag was created.') {
                    Swal.fire(
                        'Good job!',
                        'Tag is created Successful!',
                        'success'
                    )
                    reset();
                }
            })
            .catch((error) => {
                // console.log( error );
                Swal.fire(
                    'Ooops!',
                    'Tag already created!',
                    'error'
                )
            });
    }

    return (
        <div>
            <div className='px-5 py-3 shadow-md bg-white'>
                <h2 className='text-2xl font-bold flex gap-2 items-center'>
                    <FaTag className='text-2xl' />
                    <span>Add Tag</span>
                </h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className='p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center'>
                <div>
                    <h2 className='text-2xl font-semibold'>Add Tag</h2>
                    <hr className='mt-2' />
                </div>
                <div>
                    <label className="label">
                        <span className="label-text">Tag Name : </span>
                    </label>
                    <input type="text" placeholder="Enter Tag Name" className="px-3 py-3 border w-full rounded-md" {...register("tag", { required: 'Tag Name is required' })} />
                    {errors.tag && <span className='text-error'>{errors.tag.message}</span>}
                </div>

                <div className='w-full flex justify-center'>
                    <input type="submit" value="Save" className='bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white ' />
                </div>
            </form>

        </div>
    );
};

export default AddTag;